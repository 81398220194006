



























































import Vue, { PropType } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AutoCompleteUserSearch,
  SearchUserObject
} from "@/interfaces/shared/auto_complete_user_search";
import { TranslateResult } from "vue-i18n";
import {
  USER_WITH_EID_AND_NAME,
  SELECTED_USER_BY_EID_NAME,
  RESER_USER_EID_NAME,
  USER_INFO_BY_EID_NAME
} from "@/store/modules/common/constants";
import { VIEW_CANDIDATE_FILTER } from "@/store/modules/recruiter_admin/constants";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
export default Vue.extend({
  name: "AutoCompleteUserSearch",
  data(): AutoCompleteUserSearch {
    return {
      selected_searched_user: [],
      searched_users: [],
      search_input: null
    };
  },
  computed: {
    ...mapGetters("common", {
      get_user: USER_INFO_BY_EID_NAME,
      fetch_users: SELECTED_USER_BY_EID_NAME
    }),
    ...mapGetters("recruiter_admin", {
      get_candidate_filter: VIEW_CANDIDATE_FILTER
    })
  },
  props: {
    hide_details: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String as PropType<TranslateResult>
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.get_user?.length) {
      this.searched_users = this.get_user;
    }
    if (this.fetch_users?.length) {
      this.selected_searched_user = this.fetch_users;
    }
  },
  watch: {
    /**
     * Function to Fetch Users on the base of input
     */
    async search_input(value) {
      if (value !== null) {
        let payload =
          this.placeholder === this.$t("auth.name")
            ? "input=" + value
            : "eid=" + value;
        if (this.get_candidate_filter === ViewCandidatesOptions.DECLINED)
          payload += `&removed=true`;
        const result = await this.autocomplete_user_search(payload);
        if (result?.length > 0) {
          const filteredResult = result.filter(
            (item: SearchUserObject) =>
              !this.selected_searched_user?.some(
                (selectedItem: SearchUserObject) => selectedItem?.id === item.id
              )
          );
          this.searched_users = [
            ...this.selected_searched_user,
            ...filteredResult
          ];
        }
        return;
      }
    },
    placeholder() {
      if (this.selected_searched_user.length > 0) {
        this.reset_state();
        this.$emit("searched_user", []);
      }
    }
  },
  methods: {
    on_change(value: SearchUserObject[]) {
      this.set_selected_users(value);
      this.$emit("searched_user", value);
    },
    ...mapActions("common", {
      autocomplete_user_search: USER_WITH_EID_AND_NAME
    }),
    ...mapMutations("common", {
      set_selected_users: SELECTED_USER_BY_EID_NAME,
      reset_users: RESER_USER_EID_NAME
    }),
    on_clear_selected_users() {
      this.reset_state();
      this.$emit("searched_user", []);
    },
    on_input() {
      this.search_input = null;
    },
    remove_selected_item(id: number) {
      this.selected_searched_user = this.selected_searched_user.filter(
        (item: SearchUserObject) => item.id !== id
      );
      this.$emit("searched_user", this.selected_searched_user);
    },
    reset_state() {
      this.selected_searched_user = [];
      this.searched_users = [];
      this.search_input = null;
      this.reset_users();
    },
    display_name(item: SearchUserObject) {
      return `${item.first_name} ${item.last_name ? item.last_name : ""}`;
    }
  }
});
