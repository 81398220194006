

























































import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { TranslateResult } from "vue-i18n";
import { USER_WITH_EID_AND_NAME } from "@/store/modules/common/constants";
import { APP_ENG_ASSESSMENT_LISTING } from "@/store/modules/recruiter/constants";
import { AppEngAssessmentListing } from "@/store/modules/recruiter/interfaces";
export default Vue.extend({
  name: "EnglishAutoCompleteUserSearch",
  data() {
    return {
      searched_users: [] as SearchUserObject[],
      search_input: null as string | null
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_eng_assessment_listing: APP_ENG_ASSESSMENT_LISTING
    }),
    CurrentEngAssessment(): AppEngAssessmentListing {
      return this.get_eng_assessment_listing(this.version);
    }
  },
  props: {
    hide_details: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String as PropType<TranslateResult>
    },
    disabled: {
      type: Boolean,
      default: false
    },
    version: {
      type: String,
      required: true
    }
  },
  mounted() {
    if (this.CurrentEngAssessment.selected_candidates?.length) {
      this.searched_users = this.CurrentEngAssessment.selected_candidates;
    }
  },
  watch: {
    /**
     * Function to Fetch Users on the base of input
     */
    async search_input(value) {
      if (value !== null) {
        let payload =
          this.placeholder === this.$t("auth.name")
            ? "input=" + value
            : "eid=" + value;
        const result = await this.autocomplete_user_search(payload);
        if (result?.length > 0) {
          const filteredResult = result.filter(
            (item: SearchUserObject) =>
              !this.CurrentEngAssessment.selected_candidates?.some(
                (selectedItem: SearchUserObject) => selectedItem?.id === item.id
              )
          );
          this.searched_users = [
            ...this.CurrentEngAssessment.selected_candidates,
            ...filteredResult
          ];
        }
        return;
      }
    },
    placeholder() {
      this.on_clear_selected_users();
    }
  },
  methods: {
    on_change(value: SearchUserObject[]) {
      this.$emit("searched_user", value);
    },
    ...mapActions("common", {
      autocomplete_user_search: USER_WITH_EID_AND_NAME
    }),
    on_clear_selected_users() {
      this.reset_state();
      this.$emit("searched_user", []);
    },
    on_input() {
      this.search_input = null;
    },
    remove_selected_item(id: number) {
      this.CurrentEngAssessment.selected_candidates =
        this.CurrentEngAssessment.selected_candidates.filter(
          (item: SearchUserObject) => item.id !== id
        );
    },
    reset_state() {
      this.CurrentEngAssessment.selected_candidates = [];
      this.searched_users = [];
      this.search_input = null;
    },
    display_name(item: SearchUserObject) {
      return `${item.first_name} ${item.last_name ? item.last_name : ""}`;
    }
  }
});
