
















































import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "DeleteItem",
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    sub_title: {
      type: String as PropType<TranslateResult>
    },
    submit_btn_text: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    del_icon: {
      type: String,
      default: require("@/assets/icons/bold/trash.svg")
    },
    transform_icon_type: {
      type: String,
      default: "fill"
    }
  },
  methods: {
    transform_del_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute(this.transform_icon_type, "#E32D6B");
      return icon;
    }
  }
});
