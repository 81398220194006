





















import Vue from "vue";

export default Vue.extend({
  name: "RefreshButton",
  data() {
    return {};
  },
  props: {
    is_tooltip: {
      type: Boolean,
      default: false
    }
  }
});
