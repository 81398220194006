var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"date-picker-wrapper"},[_c('v-form',[_c('v-menu',{ref:"date_picker_menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('v-text-field',{staticClass:"date-picker-field",attrs:{"hide-details":"","solo":"","clearable":"","placeholder":_vm.$t('recruiter.job-listing.date-filter'),"readonly":""},on:{"click:clear":_vm.clear_dates},model:{value:(
              _vm.CurrentAnnotationAssessment.selected_dates.date_range_value
            ),callback:function ($$v) {_vm.$set(_vm.CurrentAnnotationAssessment.selected_dates, "date_range_value", $$v)},expression:"\n              CurrentAnnotationAssessment.selected_dates.date_range_value\n            "}}),_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('inline-svg',{attrs:{"transformSource":_vm.transform_calender_icon,"src":require('@/assets/icons/linear/calendar-edit.svg')}})],1)],1)]}}]),model:{value:(_vm.CurrentAnnotationAssessment.selected_dates.date_picker_menu),callback:function ($$v) {_vm.$set(_vm.CurrentAnnotationAssessment.selected_dates, "date_picker_menu", $$v)},expression:"CurrentAnnotationAssessment.selected_dates.date_picker_menu"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","range":""},on:{"change":function($event){return _vm.format_date_range(
            _vm.CurrentAnnotationAssessment.selected_dates.date_filter
          )}},model:{value:(_vm.CurrentAnnotationAssessment.selected_dates.date_filter),callback:function ($$v) {_vm.$set(_vm.CurrentAnnotationAssessment.selected_dates, "date_filter", $$v)},expression:"CurrentAnnotationAssessment.selected_dates.date_filter"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }