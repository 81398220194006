


























































































































































































































































































































































































import Vue from "vue";
import AppAssessmentListingFilterHeader from "@/components/recruiter/app_assessment/AppAssessmentListingFilterHeader.vue";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";
import { mapActions, mapGetters, mapMutations } from "vuex";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import {
  APP_ASSESSMENTS,
  SITE_DIRECTION
} from "@/store/modules/common/constants";
import { AssessmentListingHeaderOptions } from "@/interfaces/responses/assessments/assessments";
import {
  ACTIVE_ASSESSMENT,
  APP_ENG_ASSESSMENT_LISTING,
  RESET_ASSESSMENT
} from "@/store/modules/recruiter/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import {
  format_date,
  get_app_assessment_status,
  get_app_assessment_status_color,
  get_color_for_percentage,
  get_pass_or_fail_class,
  assessment_pass_fail_result
} from "@/utils/global";
import { get_color_by_name } from "@/utils/colors";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import DeleteItem from "@/components/shared/DeleteItem.vue";
import { AppEngAssessmentListing } from "@/store/modules/recruiter/interfaces";
import { User } from "@/interfaces/data_objects/user";
import OnBoardingInvitationCard from "@/components/recruiter/OnBoardingInvitationCard.vue";
import { USER_DETAILS_BY_ID } from "@/store/modules/auth/constants";
import RefreshButton from "@/components/shared/RefreshButton.vue";

export default Vue.extend({
  name: "AppEngAssessmentReport",
  components: {
    AppAssessmentListingFilterHeader,
    DataNotFound,
    DeleteItem,
    OnBoardingInvitationCard,
    RefreshButton
  },
  data() {
    return {
      loading: false, // Loading state of the page
      data_error: false, // Error state of the page
      assessment: null as AppAssessments.Assessment | null, // Assessment object
      table_headers: [
        { title: this.$t("assessments.listing.col-1").toString(), cols: 1 },
        { title: this.$t("assessments.listing.col-4").toString(), cols: 2 },
        { title: this.$t("assessments.listing.col-2").toString(), cols: 2 },
        {
          title: this.$t("assessments.listing.col-score").toString(),
          cols: 2,
          sortable: true,
          direction: "asc"
        },
        { title: this.$t("assessments.listing.col-3").toString(), cols: 3 },
        {
          title: this.$t("assessments.listing.col-5").toString(),
          cols: 2,
          is_btn: true
        }
      ] as AssessmentListingHeaderOptions[],
      delete_icon: require("@/assets/icons/bold/reset.svg"),
      is_delete: false,
      selected_user: {
        id: 0,
        last_name: "",
        first_name: "",
        avatar_uri: ""
      } as AppAssessments.AssessmentUserObject,
      delete_btn_loading: false,
      version: "v1" as string,
      on_boarding_invitation: false,
      user_object: {} as User,
      status: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    const version = to.params.version;
    if (version === "v1" || version === "v2") {
      next();
    } else {
      next({ name: "eng-assessment-not-found" });
    }
  },
  beforeRouteUpdate(to, from, next) {
    const version = to.params.version;
    if (version === "v1" || version === "v2") {
      next();
    } else {
      next({ name: "eng-assessment-not-found" });
    }
  },
  created() {
    this.version = this.$route.params.version || "v1";
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS,
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      app_eng_assessment_listing: APP_ENG_ASSESSMENT_LISTING
    }),
    not_found_error() {
      return this.$t("errors.english-assessments-not-found").toString();
    },
    AppAssessmentsStatus() {
      return AppAssessments.AppAssessmentStatus;
    },
    CurrentAssessment(): AppEngAssessmentListing {
      const clonedListing = {
        ...this.app_eng_assessment_listing(this.version)
      };
      clonedListing.results.sort((a: any, b: any) => {
        const overall_score_A = a.result?.overall_score ?? 0;
        const overall_score_B = b.result?.overall_score ?? 0;

        if (this.table_headers[3].direction === "asc")
          return overall_score_B - overall_score_A;
        return overall_score_A - overall_score_B;
      });
      return clonedListing;
    }
  },
  watch: {
    "CurrentAssessment.selected_dates.date_filter": function (
      new_value: string[],
      old_value: string[]
    ) {
      if (
        (new_value.length === 2 || new_value.length === 0) &&
        new_value.length !== old_value.length
      )
        this.process_assessment_listing(1);
    },
    "CurrentAssessment.selected_candidates": function (
      new_value: SearchUserObject[],
      old_value: SearchUserObject[]
    ) {
      if (new_value.length !== old_value.length)
        this.process_assessment_listing(1);
    },
    get_site_direction() {
      this.table_headers = [
        { title: this.$t("assessments.listing.col-1").toString(), cols: 1 },
        { title: this.$t("assessments.listing.col-4").toString(), cols: 2 },
        { title: this.$t("assessments.listing.col-2").toString(), cols: 2 },
        {
          title: this.$t("assessments.listing.col-score").toString(),
          cols: 2,
          sortable: true,
          direction: "asc"
        },
        { title: this.$t("assessments.listing.col-3").toString(), cols: 2 },
        { title: this.$t("assessments.listing.col-5").toString(), cols: 3 }
      ];
    }
  },
  async mounted() {
    await this.init_process();
  },
  methods: {
    format_date,
    get_app_assessment_status,
    get_app_assessment_status_color,
    get_color_for_percentage,
    get_pass_or_fail_class,
    assessment_pass_fail_result,
    ...mapActions("recruiter", {
      fetch_eng_assessment_listing: APP_ENG_ASSESSMENT_LISTING,
      delete_user_english_assessment: RESET_ASSESSMENT
    }),
    ...mapMutations("recruiter", {
      set_active_assessment: ACTIVE_ASSESSMENT
    }),
    ...mapMutations({
      set_root_notification: ROOT_NOTIFICATION,
      set_root_error: ROOT_ERROR
    }),
    ...mapActions("auth", {
      fetch_user_by_id: USER_DETAILS_BY_ID
    }),
    async init_process() {
      this.loading = true;
      const assessment_to_find =
        this.version === "v1"
          ? AppAssessmentNames.english_v1
          : AppAssessmentNames.english_v2;
      this.assessment = this.app_assessments.find(
        (val: AppAssessments.Assessment) =>
          val.assessment_type === assessment_to_find
      );
      // If assessment is not found
      if (!this.assessment) {
        this.data_error = true;
        this.loading = false;
        return;
      }
      this.set_active_assessment({
        assessment_type: this.assessment.assessment_type,
        assessment_id: this.assessment.id
      });
      // Fetch assessment listing
      await this.fetch_assessment_by_pagination(
        this.CurrentAssessment.page,
        this.CurrentAssessment.limit,
        this.assessment.id
      );
    },
    async process_assessment_listing(page: number) {
      if (!this.assessment) return;
      await this.fetch_assessment_by_pagination(
        page,
        this.CurrentAssessment.limit,
        this.assessment.id
      );
    },
    /**
     * Fetch assessment listing
     * @param page Page number
     * @param limit Limit of the page
     * @param assessment_id Assessment id
     */
    async fetch_assessment_by_pagination(
      page = 1,
      limit: number,
      assessment_id: number
    ) {
      this.loading = true;
      const res = await this.fetch_eng_assessment_listing({
        page,
        limit,
        assessment_id,
        selected_dates: this.CurrentAssessment.selected_dates,
        selected_candidates: this.CurrentAssessment.selected_candidates,
        version: this.version,
        score_filter: this.CurrentAssessment.score_filter,
        status_filter: this.CurrentAssessment.status_filter
      });
      if (!res) {
        this.data_error = true;
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    transform_view_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("success");
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    view_assessment_result(assessment_result_id: number, user_id: number) {
      this.$router.push(
        `/recruiter/assessments/english/${this.version}/${assessment_result_id}/view/${user_id}`
      );
    },
    transform_trash_icon(icon: HTMLElement): HTMLElement {
      const color = "#FF4066";
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    delete_assessment(user: AppAssessments.AssessmentUserObject) {
      this.selected_user = user;
      this.delete_btn_loading = false;
      this.is_delete = true;
    },
    async dialog_action(value: boolean) {
      if (value) {
        this.delete_btn_loading = true;
        const response = await this.delete_user_english_assessment({
          assessment_id: this.assessment?.id,
          user_id: this.selected_user.id
        });
        if (response) {
          this.set_root_notification(
            `${this.selected_user.first_name} ${
              this.selected_user.last_name
            } ${this.$t("assessments.assessment-reset")}`
          );
          this.process_assessment_listing(1);
        } else {
          this.set_root_error(
            `${this.selected_user.first_name} ${
              this.selected_user.last_name
            } ${this.$t("assessments.assessment-reset-failed")}`
          );
        }
        this.is_delete = false;
      }
    },
    update_filter(filter: string) {
      this.CurrentAssessment.score_filter = filter;
      this.process_assessment_listing(1);
    },
    get_assessment(assessment: AppAssessments.AssessmentResults) {
      return (
        assessment.status !== AppAssessments.AppAssessmentStatus.IN_PROGRESS &&
        assessment.status !==
          AppAssessments.AppAssessmentStatus.RESULT_PROCESSING
      );
    },
    sort_assessment(ind: number) {
      this.table_headers[ind].direction =
        this.table_headers[ind].direction === "asc" ? "desc" : "asc";
    },
    async open_user_details(
      user: AppAssessments.AssessmentUserObject,
      status: AppAssessments.AppAssessmentStatus
    ) {
      const response = await this.fetch_user_by_id(user.id);
      if (response) {
        this.user_object = response;
        this.on_boarding_invitation = true;
        this.status = status;
      }
    },
    update_status_filter(filter: string) {
      this.CurrentAssessment.status_filter = filter;
      this.process_assessment_listing(1);
    },
    check_status(assessment: AppAssessments.AssessmentResults) {
      if (assessment.status)
        return [
          AppAssessments.AppAssessmentStatus.IN_PROGRESS,
          AppAssessments.AppAssessmentStatus.CANCELLED,
          AppAssessments.AppAssessmentStatus.FAILED
        ].includes(assessment.status);
      return false;
    },
    refresh_listing() {
      if (this.assessment?.id)
        this.fetch_assessment_by_pagination(
          this.CurrentAssessment.page,
          this.CurrentAssessment.limit,
          this.assessment?.id
        );
    }
  }
});
