























import { SelectedFilters } from "@/interfaces/shared/selected_filters";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "SelectOptions",
  data() {
    return {
      selected_option: this.$t("assessments.both"),
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg")
    };
  },
  props: {
    option: {
      type: String,
      default: "both"
    },
    filters: {
      type: Array as PropType<SelectedFilters[]>
    }
  },
  watch: {
    option: {
      immediate: true,
      handler(newVal) {
        this.selected_option = newVal;
      }
    }
  },
  methods: {
    option_change(filter: string) {
      this.$emit("option_change", filter);
    }
  }
});
