import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import { SearchedCandidate } from "../recruiter/interfaces";

export interface RecruiterAdmin {
  candidates: AllCandidates;
  view_candidate_data: SearchedCandidate | null;
  view_candidate_filter: ViewCandidatesOptions;
  al_ain_users: boolean;
}

export interface AllCandidates {
  results: SearchedCandidate[];
  total: number;
  pagination: number;
}
export interface GetAllCandidatesPayload {
  page?: number;
  limit_per_page?: number;
  removed?: boolean;
  approved?: boolean;
  registered?: boolean;
  al_ain_users?: boolean;
  user_ids?: number[];
}

export interface ActionCandidatePayload {
  user_ids: number[];
  action: ActionCandidate;
}

export enum ActionCandidate {
  ACTIVATE = "activate",
  DEACTIVATE = "deactivate",
  ACTIVATE_EMAIL = "activate_email",
  DEACTIVATE_EMAIL = "deactivate_email"
}
