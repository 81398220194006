import { render, staticRenderFns } from "./FiltersBadge.vue?vue&type=template&id=520814c8&scoped=true&"
import script from "./FiltersBadge.vue?vue&type=script&lang=ts&"
export * from "./FiltersBadge.vue?vue&type=script&lang=ts&"
import style0 from "./FiltersBadge.vue?vue&type=style&index=0&id=520814c8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520814c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VBadge,VCheckbox})
